import React, { useState } from "react";
import tick from "../../Icons/tick.png";
import summryFrame from "../../Icons/summryFrame.png";
import stock from "../../Icons/stock.png";
import { Grid, Typography, Card } from "@mui/material";
import { PopupButton } from "react-calendly";
import styles from "./success.module.scss";

const Success = () => {
  let url = useState(
    "https://calendly.com/discovery-call-adugeeks/15-minute-discovery-call?month=2022-09"
  );
  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12}>
        <div className={styles.mainContainer}>
          <section className={styles.subContainer}>
            <div className={styles.reportContainer}>
              <Typography className={styles.reportTitle}>
                Your report is ready
              </Typography>
              <Typography className={styles.reportSubTitle}>
                You are one step closer to lorem ipsum dolor sit amet.
              </Typography>
              <Card className={styles.reportCard}>
                <img src={tick} alt="" />

                <Typography className={styles.reportName}>
                  Lorem Ipsum dolor sit amet et nomine{" "}
                </Typography>
                <section className={styles.shareTitle}>
                  {/* <img src={share} alt="" /> */}
                </section>
              </Card>
              <div className={styles.btnContainer}>
                <PopupButton
                  url={url}
                  rootElement={document.getElementById("root")}
                  text="SCHEDULE A FREE CONSULTATION"
                  className={styles.consultationBtn}
                />
              </div>
            </div>
            <div className={styles.rightImgContainer}>
              <img src={summryFrame} alt="" />
            </div>
          </section>
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <div className={styles.mobileContainer}>
          <p className={styles.mobileReportTitle}>Your report is ready</p>
          <p className={styles.mobileReportSubTitle}>
            You are one step closer to lorem ipsum dolor sit amet.
          </p>
          <div className={styles.mobileReportCard}>
            <div className={styles.mobileReportSubCard}>
              <section className={styles.dataContainer}>
                <img src={tick} alt="" className={styles.dataTick} />
                <Typography className={styles.dataReport}>
                  Lorem Ipsum dolor sit amet et nomine{" "}
                </Typography>
              </section>
            </div>
            <div className={styles.btnMobileContainer}>
              <PopupButton
                url={url}
                rootElement={document.getElementById("root")}
                text="SCHEDULE A FREE CONSULTATION"
                className={styles.consultationMobileBtn}
              />
            </div>
            <div className={styles.leftImgContainer}>
              <img src={stock} alt=""className={styles.leftImg}/>
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default Success;
