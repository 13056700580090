import { Grid, Button } from "@mui/material";
import Order from "./order/order";
import Summary from "./summary/summary";
import React, { useEffect, useCallback, useState } from "react";
import axios from "axios";
import RightIcon from "../../../assets/icons/RightIcon.png";
import MobileViewSummary from "./mobileViewSummary/MobileViewSummary";
import styles from "./step.module.scss";

const Step7 = ({ stepSevenSubmit, currentpage, setCurrentPage, ...other }) => {
  const { formValue, setFormValue, step, setStep } = other;
  const [click, setClick] = useState(false);

  const handleSubmit = useCallback(async () => {
    setClick(true);
    try {
      const response = await axios.post(
        "https://api.adugeeks.us/createCheckoutSession",
        { price: formValue.priceId }
      );
      setFormValue((formValue) => ({
        ...formValue,
        transactionId: response?.data?.sessionId,
      }));
      window.open(response?.data?.checkoutUrl, "_blank", 'width:480px,height:480px');
    } catch (error) {
      console.log("failed!");
    }
  }, [formValue, setFormValue]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (click) {
      setTimeout(() => {
        setClick(false);
      }, 5000);
    }
  }, [click]);
  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} sx={{mt:'4'}}>
        <div className={styles.mobileContainer}>
          <Order
            formValue={formValue}
            setFormValue={setFormValue}
            step={step}
            setStep={setStep}
            stepSevenSubmit={stepSevenSubmit}
          />
          <MobileViewSummary
            setStep={setStep}
            currentpage={currentpage}
            setCurrentPage={setCurrentPage}
            formValue={formValue}
          />
          <div className={styles.btnContainer}>
            {click === true ? (
              <Button
                variant="contained"
                className={styles.nextBtn}
                onClick={() => {}}
                style={{ backgroundColor: "#fb651f" }}
              >
                Please Wait
              </Button>
            ) : (
              <Button
                variant="contained"
                className={styles.nextBtn}
                onClick={handleSubmit}
                style={{ backgroundColor: "#fb651f" }}
              >
                SUBMIT <img src={RightIcon} alt="" />
              </Button>
            )}
          </div>
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <div className={styles.mainContainer}>
          <div className={styles.subContainer}>
            <section className={styles.innerContainer}>
              <div className={styles.component}>
                <Summary
                  setStep={setStep}
                  currentpage={currentpage}
                  setCurrentPage={setCurrentPage}
                  formValue={formValue}
                />
              </div>
              <div className={styles.component}>
                <Order
                  formValue={formValue}
                  setFormValue={setFormValue}
                  step={step}
                  setStep={setStep}
                  stepSevenSubmit={stepSevenSubmit}
                />
              </div>
            </section>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default Step7;
