// @ts-nocheck
import {
  Grid,
  Typography,
  Button,
  Card,
  CardContent,
  FormHelperText,
} from "@mui/material";
import React, { useEffect } from "react";
import LeftIcon from "../../../assets/icons/LeftIcon.png";
import RightIcon from "../../../assets/icons/RightIcon.png";
import infoIcon from "../../../assets/icons/infoIcon.png";
import Frame2 from "../../../assets/icons/Frame2.png";
import Frame1 from "../../../assets/icons/Frame1.png";
import Frame4 from "../../../assets/icons/Frame4.png";
import Frame3 from "../../../assets/icons/Frame3.png";
import i from "../../../assets/icons/i.png";
import * as Yup from "yup";
import { Formik } from "formik";
import isMountedRef from "../../../Hooks/useIsMountedRef";
import styles from "./step.module.scss";

const Step4 = ({ stepFourSubmit, ...other }) => {
  const { formValue, setFormValue, step, setStep } = other;

  const initialValues = {
    aduType1: formValue.aduType1,
    aduType2: formValue.aduType2,
    specific: formValue.specific,
  };

  const onSubmit = async (
    formValue,
    { setErrors, setStatus, setSubmitting }
  ) => {
    try {
      console.log("isSubmitting", formValue);
      stepFourSubmit(formValue);
    } catch (error) {
      // @ts-ignore
      if (isMountedRef.current) {
        alert("Error");
        setStatus({ success: false });
        setErrors({ submit: error });
        setSubmitting(false);
      }
    }
  };

  const validationSchema = Yup.object().shape({
    aduType1: Yup.string().required("This field is required"),
    aduType2: Yup.string().required("This field is required"),
    specific: Yup.string().required("This field is required"),
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  console.log(formValue, "formValue");

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        enableReinitialize
        validationSchema={validationSchema}
        // sx={{ height: "100%" }}
      >
        {({
          errors,
          handleBlur,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Grid container>
              <Grid item xs={12} sm={12} md={12}>
                <div className={styles.mainContainer}>
                  <section className={styles.subContainer}>
                    <Typography className={styles.title}>
                      ADU Preferences
                    </Typography>
                    <Typography className={styles.subTitle}>
                      You are able to build up to 1 Accessory Dwelling Unit and
                      1 Junior Accessory Dwelling Unit.
                    </Typography>
                    <div className={styles.selectAdu}>
                      <section className={styles.selectTitleContainer}>
                        <Typography className={styles.selectTitle}>
                          Select ADU type{" "}
                        </Typography>
                        <img src={infoIcon} alt="" />
                      </section>
                      <Typography className={styles.selectSubTitle}>
                        Lorem ipsum dolor sit amet, et nomine patri.
                      </Typography>

                      <section className={styles.selectCardContainer}>
                        <label>
                          <Card
                            className={`${
                              formValue.aduType1 === "ADU"
                                ? styles.accessoryActiveCard
                                : styles.accessoryInActiveCard
                            }`}
                          >
                            <section className={styles.checkBoxContainer}>
                              <input
                                type="checkbox"
                                checked={formValue.aduType1 === "ADU"}
                                value={formValue.aduType1}
                                onChange={(e) => {
                                  setFormValue({
                                    ...formValue,
                                    aduType1: "ADU",
                                  });
                                }}
                              />
                            </section>

                            <CardContent className={styles.accessoryContent}>
                              <img src={Frame1} alt="" />

                              <Typography className={styles.accessoryCardTitle}>
                                Accessory Dwelling Unit
                              </Typography>
                            </CardContent>
                          </Card>
                          {touched.aduType1 && errors.aduType1 && (
                            <FormHelperText
                              error
                              id="standard-weight-helper-text-description"
                              className={styles.requiredBox}
                            >
                              {errors.aduType1}
                            </FormHelperText>
                          )}
                        </label>

                        <label>
                          <Card
                            className={`${
                              formValue.aduType1 === "JADU"
                                ? styles.accessoryActiveCard
                                : styles.accessoryInActiveCard
                            }`}
                          >
                            <section className={styles.checkBoxContainer}>
                              <input
                                type="checkbox"
                                checked={formValue.aduType1 === "JADU"}
                                value={formValue.aduType1}
                                onChange={(e) => {
                                  setFormValue({
                                    ...formValue,
                                    aduType1: "JADU",
                                  });
                                }}
                              />
                            </section>

                            <CardContent className={styles.accessoryContent}>
                              <img src={Frame2} alt="" />
                              <Typography className={styles.accessoryCardTitle}>
                                Junior Accessory Dwelling Unit
                              </Typography>
                            </CardContent>
                          </Card>
                          {touched.aduType1 && errors.aduType1 && (
                            <FormHelperText
                              error
                              id="standard-weight-helper-text-description"
                              className={styles.requiredBox}
                            >
                              {errors.aduType1}
                            </FormHelperText>
                          )}
                        </label>

                        <label>
                          <Card
                            className={`${
                              formValue.aduType1 === "not sure"
                                ? styles.accessoryActiveCard
                                : styles.accessoryInActiveCard
                            }`}
                          >
                            <section className={styles.checkBoxContainer}>
                              <input
                                type="checkbox"
                                checked={formValue.aduType1 === "not sure"}
                                value={formValue.aduType1}
                                onChange={(e) => {
                                  setFormValue({
                                    ...formValue,
                                    aduType1: "not sure",
                                  });
                                }}
                              />
                            </section>

                            <CardContent className={styles.accessoryContent}>
                              <img src={i} alt="" />

                              <Typography className={styles.accessoryCardTitle}>
                                I’m not sure
                              </Typography>
                            </CardContent>
                          </Card>
                          {touched.aduType1 && errors.aduType1 && (
                            <FormHelperText
                              error
                              id="standard-weight-helper-text-description"
                              className={styles.requiredBox}
                            >
                              {errors.aduType1}
                            </FormHelperText>
                          )}
                        </label>
                      </section>
                    </div>
                    <div className={styles.selectAdu}>
                      <section className={styles.selectTitleContainer}>
                        <Typography className={styles.selectTitle}>
                          Which of the following do you wish to have?{" "}
                        </Typography>
                      </section>
                      <Typography className={styles.selectSubTitle}>
                        Lorem ipsum dolor sit amet, et nomine patri.
                      </Typography>
                      <section className={styles.selectCardContainer}>
                        <label>
                          <Card
                            className={`${
                              formValue.aduType2 === "Garage"
                                ? styles.accessoryActiveCard
                                : styles.accessoryInActiveCard
                            }`}
                          >
                            <section className={styles.checkBoxContainer}>
                              {" "}
                              <input
                                type="checkbox"
                                checked={formValue.aduType2 === "Garage"}
                                value={formValue.aduType2}
                                onChange={(e) => {
                                  setFormValue({
                                    ...formValue,
                                    aduType2: "Garage",
                                  });
                                }}
                              />{" "}
                            </section>

                            <CardContent className={styles.accessoryContent}>
                              <img src={Frame2} alt="" />
                              <Typography className={styles.accessoryCardTitle}>
                                Garage
                              </Typography>
                            </CardContent>
                          </Card>
                          {touched.aduType2 && errors.aduType2 && (
                            <FormHelperText
                              error
                              id="standard-weight-helper-text-description"
                              className={styles.requiredBox}
                            >
                              {errors.aduType2}
                            </FormHelperText>
                          )}
                        </label>

                        <label>
                          <Card
                            className={`${
                              formValue.aduType2 === "Detached"
                                ? styles.accessoryActiveCard
                                : styles.accessoryInActiveCard
                            }`}
                          >
                            <section className={styles.checkBoxContainer}>
                              <input
                                type="checkbox"
                                checked={formValue.aduType2 === "Detached"}
                                onChange={(e) => {
                                  setFormValue({
                                    ...formValue,
                                    aduType2: "Detached",
                                  });
                                }}
                              />
                            </section>

                            <CardContent className={styles.accessoryContent}>
                              <img src={Frame4} alt="" />
                              <Typography className={styles.accessoryCardTitle}>
                                Detached
                              </Typography>
                            </CardContent>
                          </Card>
                          {touched.aduType2 && errors.aduType2 && (
                            <FormHelperText
                              error
                              id="standard-weight-helper-text-description"
                              className={styles.requiredBox}
                            >
                              {errors.aduType2}
                            </FormHelperText>
                          )}
                        </label>

                        <label>
                          <Card
                            className={`${
                              formValue.aduType2 === "Above Garage"
                                ? styles.accessoryActiveCard
                                : styles.accessoryInActiveCard
                            }`}
                          >
                            <section className={styles.checkBoxContainer}>
                              {" "}
                              <input
                                type="checkbox"
                                checked={formValue.aduType2 === "Above Garage"}
                                onChange={(e) => {
                                  setFormValue({
                                    ...formValue,
                                    aduType2: "Above Garage",
                                  });
                                }}
                              />
                            </section>

                            <CardContent className={styles.accessoryContent}>
                              <img src={Frame3} alt="" />
                              <Typography className={styles.accessoryCardTitle}>
                                Above Garage
                              </Typography>
                            </CardContent>
                          </Card>
                          {touched.aduType2 && errors.aduType2 && (
                            <FormHelperText
                              error
                              id="standard-weight-helper-text-description"
                              className={styles.requiredBox}
                            >
                              {errors.aduType2}
                            </FormHelperText>
                          )}
                        </label>
                      </section>
                    </div>
                    <div className={styles.specialContainer}>
                      <Typography className={styles.specialTitle}>
                        Do you know what are you looking specifically?
                      </Typography>

                      <Typography className={styles.specialSubTitle}>
                        Regarding size, bedroom and bathroom count, or would you
                        like us to provide you with options?
                      </Typography>

                      <section className={styles.selectInputContainer}>
                        <Button
                          variant="outlined"
                          type="button"
                          value={formValue.specific}
                          onClick={(e) => {
                            setFormValue({
                              ...formValue,
                              specific: "Whatever I can do",
                            });
                          }}
                          fullWidth
                          className={`${
                            formValue.specific === "Whatever I can do"
                              ? styles.specialLeftActiveBtn
                              : styles.specialLeftInActiveBtn
                          }`}
                        >
                          Whatever I can do
                        </Button>

                        <Button
                          variant="outlined"
                          required
                          type="button"
                          value={formValue.specific}
                          onClick={(e) => {
                            setFormValue({
                              ...formValue,
                              specific: "Maximum Size",
                            });
                          }}
                          fullWidth
                          className={`${
                            formValue.specific === "Maximum Size"
                              ? styles.specialRightBtnActiveBtn
                              : styles.specialRightBtnInActiveBtn
                          }`}
                        >
                          Maximum Size
                        </Button>
                      </section>

                      <section className={styles.selectInputContainer}>
                        <Button
                          variant="outlined"
                          type="button"
                          value={formValue.specific}
                          onClick={(e) => {
                            setFormValue({
                              ...formValue,
                              specific:
                                "Not sure, please make a recommendation",
                            });
                          }}
                          fullWidth
                          className={`${
                            formValue.specific ===
                            "Not sure, please make a recommendation"
                              ? styles.specialLeftActiveBtn
                              : styles.specialLeftInActiveBtn
                          }`}
                        >
                          Not sure, please make a recommendation
                        </Button>

                        <Button
                          variant="outlined"
                          type="button"
                          value={formValue.specific}
                          onClick={(e) => {
                            setFormValue({
                              ...formValue,
                              specific:
                                "I would like to select options  my ADU",
                            });
                          }}
                          fullWidth
                          className={`${
                            formValue.specific ===
                            "I would like to select options  my ADU"
                              ? styles.specialRightBtnActiveBtn
                              : styles.specialRightBtnInActiveBtn
                          }`}
                        >
                          I would like to select options for my ADU
                        </Button>
                      </section>
                    </div>
                    {touched.specific && errors.specific && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-description"
                        className={styles.requiredBox1}
                      >
                        {errors.specific}
                      </FormHelperText>
                    )}
                    <div className={styles.btnContainer}>
                      <Button
                        variant="outlined"
                        type="button"
                        className={styles.backBtn}
                        onClick={() => setStep(step - 1)}
                      >
                        <img src={LeftIcon} alt="" /> BACK{" "}
                      </Button>
                      <Button
                        variant="contained"
                        className={styles.nextBtn}
                        // onClick={onSubmit}
                        disabled={isSubmitting}
                        type="submit"
                      >
                        NEXT <img src={RightIcon} alt="" />
                      </Button>
                    </div>
                  </section>
                </div>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </>
  );
};

export default Step4;
