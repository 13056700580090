import React, { useState } from "react";
import summryFrame from "../../Icons/summryFrame.png";
import stock from "../../Icons/stock.png";
import { Grid, Typography, Card } from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { PopupButton } from "react-calendly";
import styles from "./cancel.module.scss";

const Cancel = () => {
  let url = useState(
    "https://calendly.com/discovery-call-adugeeks/15-minute-discovery-call?month=2022-09"
  );
  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12}>
        <div className={styles.mainContainer}>
          <section className={styles.subContainer}>
            <div className={styles.reportContainer}>
              <Typography className={styles.reportTitle}>
                Payment Failed!
              </Typography>
              <Typography className={styles.reportSubTitle}>
                You are one step closer to lorem ipsum dolor sit amet.
              </Typography>
              <Card className={styles.reportCard}>
                <CancelOutlinedIcon sx={{ mt: 4, mb: 4, color: "red" }} />
                <Typography className={styles.reportName}>
                  Unable to process the payment due to technical issue try again
                </Typography>
                <section className={styles.shareTitle}>
                  {/* <img src={share} alt="" />  */}
                </section>
              </Card>
              <div className={styles.btnContainer}>
                <PopupButton
                  url={url}
                  rootElement={document.getElementById("root")}
                  text="SCHEDULE A FREE CONSULTATION"
                  className={styles.consultationBtn}
                />
              </div>
            </div>
            <div className={styles.rightImgContainer}>
              <img src={summryFrame} alt="" />
            </div>
          </section>
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <div className={styles.mobileContainer}>
          <p className={styles.mobileReportTitle}> Payment Failed!</p>
          <p className={styles.mobileReportSubTitle}>
            You are one step closer to lorem ipsum dolor sit amet.
          </p>
          <div className={styles.mobileReportCard}>
            <div className={styles.mobileReportSubCard}>
              <section className={styles.dataContainer}>
              <CancelOutlinedIcon sx={{ mt: 4, mb: 4, color: "red" }} />
                {/* <img src={summryFrame} alt="" className={styles.dataTick} /> */}
                <Typography className={styles.dataReport}>
                  Unable to process the payment due to technical issue try again
                </Typography>
              </section>
            </div>
            <div className={styles.btnMobileContainer}>
              <PopupButton
                url={url}
                rootElement={document.getElementById("root")}
                text="SCHEDULE A FREE CONSULTATION"
                className={styles.consultationMobileBtn}
              />
            </div>
            <div className={styles.leftImgContainer}>
              <img src={stock} alt="" className={styles.leftImg} />
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default Cancel;
