// @ts-nocheck
import {
  Card,
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Button,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import * as Yup from "yup";
// @ts-ignore
import { Formik } from "formik";
import isMountedRef from "../../../Hooks/useIsMountedRef";
import Slide from "../../slider/slide";
import LeftIcon from "../../../assets/icons/LeftIcon.png";
import RightIcon from "../../../assets/icons/RightIcon.png";
import React, { useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import styles from "./step.module.scss";

const Step5 = ({ stepFiveSubmit, ...other }) => {
  const { formValue, setFormValue, step, setStep } = other;
  const theme = createTheme({
    palette: {
      primary: {
        main: "#212121",
      },
    },
  });

  const initialValues = {
    sqft1: formValue.sqft1,
    sqft2: formValue.sqft2,
    aduBedroom: formValue.aduBedroom,
    aduBathroom: formValue.aduBathroom,
    jaduBedroom: formValue.jaduBedroom,
    jaduBathroom: formValue.jaduBathroom,
  };

  const onSubmit = async (
    formValue,
    { setErrors, setStatus, setSubmitting }
  ) => {
    try {
      console.log("isSubmitting", formValue);
      stepFiveSubmit(formValue);
    } catch (error) {
      // @ts-ignore
      if (isMountedRef.current) {
        alert("Error");
        setStatus({ success: false });
        setErrors({ submit: error });
        setSubmitting(false);
      }
    }
  };

  const validationSchema = Yup.object().shape({
    sqft1: Yup.string().required("Please choose the required square  feet"),
    sqft2: Yup.string().required("Please choose the required square  feet"),
    aduBedroom: Yup.string().required("This field is required"),
    aduBathroom: Yup.string().required("This field is required"),
    jaduBedroom: Yup.string().required("This field is required"),
    jaduBathroom: Yup.string().required("This field is required"),
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        enableReinitialize
        validationSchema={validationSchema}
      >
        {({
          errors,
          handleBlur,
          handleSubmit,
          isSubmitting,
          touched,
          // @ts-ignore
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Grid container>
              <Grid item xs={12} sm={12} md={12}>
                <div className={styles.mainContainer}>
                  <section className={styles.subContainer}>
                    <Typography className={styles.title}>
                      Select your ADU options
                    </Typography>

                    <Typography className={styles.subTitle}>
                      Lorem ipsum dolor sit amet et nomine patri, et sanctis
                      nom, et fili nomine sacti.
                    </Typography>
                    <div className={styles.optionContainer}>
                      <section className={styles.optionSubContainer}>
                        <Card className={styles.cardOne}>
                          <Typography className={styles.cardTitle}>
                            Option 1 ADU
                          </Typography>
                          <Typography className={styles.cardSubTitle}>
                            Select your preferences.
                          </Typography>
                          <div className={styles.feetContainer}>
                            <Typography className={styles.feetTitle}>
                              Square feet
                            </Typography>
                            <Typography className={styles.maxTitle}>
                              (Max.1200)
                            </Typography>
                          </div>
                          <div className={styles.slider}>
                            <Slide
                              defaultValue={500}
                              step={100}
                              min={0}
                              max={1200}
                              value={formValue.sqft1}
                              // @ts-ignore
                              onBlur={handleBlur}
                              error={Boolean(touched.sqft1 && errors.sqft1)}
                              helperText={touched.sqft1 && errors.sqft1}
                              handleChange={(e) => {
                                setFormValue({
                                  ...formValue,
                                  sqft1: e.target.value,
                                  priceId: "price_1M90a4GUWr06e0k5LKr1Mp2J",
                                });
                              }}
                            />
                            {touched.sqft1 && errors.sqft1 && (
                              <FormHelperText
                                error
                                id="standard-weight-helper-text-description"
                              >
                                {errors.sqft1}
                              </FormHelperText>
                            )}
                          </div>
                          <div className={styles.checbBoxContainer}>
                            <input
                              type="checkbox"
                              id="option1"
                              name="option1"
                              value="I want a Professional Recommendation"
                              className={styles.checkBtn}
                            />
                            <label
                              htmlFor="option1"
                              className={styles.checkLabel}
                            >
                              {" "}
                              I want a Professional Recommendation
                            </label>
                          </div>
                          <div className={styles.roomSelectContainer}>
                            <Typography className={styles.roomTitle}>
                              Bedrooms
                            </Typography>

                            <FormControl fullWidth>
                              <ThemeProvider theme={theme}>
                                <InputLabel
                                  sx={{ fontFamily: "barlowregular" }}
                                >
                                  Please select..
                                </InputLabel>
                                <Select
                                  className={styles.drop}
                                  value={formValue.aduBedroom}
                                  label="Please select.."
                                  sx={{
                                    color: "#212121",
                                    fontFamily: "barlowregular",
                                  }}
                                  onChange={(e) => {
                                    setFormValue({
                                      ...formValue,
                                      aduBedroom: e.target.value,
                                    });
                                  }}
                                  onBlur={handleBlur}
                                  error={Boolean(
                                    touched.aduBedroom && errors.aduBedroom
                                  )}
                                  // @ts-ignore
                                  helperText={
                                    touched.aduBedroom && errors.aduBedroom
                                  }
                                  inputProps={{ "aria-label": "Without label" }}
                                >
                                  <MenuItem
                                    value={1}
                                    className={styles.drop}
                                    sx={{
                                      color: "#000000",
                                      fontFamily: "barlowregular",
                                    }}
                                  >
                                    1
                                  </MenuItem>
                                  <MenuItem
                                    value={2}
                                    sx={{
                                      color: "#000000",
                                      fontFamily: "barlowregular",
                                    }}
                                  >
                                    2
                                  </MenuItem>
                                  <MenuItem
                                    value={3}
                                    sx={{
                                      color: "#000000",
                                      fontFamily: "barlowregular",
                                    }}
                                  >
                                    3
                                  </MenuItem>
                                  <MenuItem
                                    value={4}
                                    sx={{
                                      color: "#000000",
                                      fontFamily: "barlowregular",
                                    }}
                                  >
                                    4
                                  </MenuItem>
                                  <MenuItem
                                    value={"studio"}
                                    sx={{
                                      color: "#000000",
                                      fontFamily: "barlowregular",
                                    }}
                                  >
                                    Studio
                                  </MenuItem>
                                </Select>
                                {touched.aduBedroom && errors.aduBedroom && (
                                  <FormHelperText
                                    error
                                    id="standard-weight-helper-text-description"
                                    sx={{ marginLeft: "-0vh" }}
                                  >
                                    {errors.aduBedroom}
                                  </FormHelperText>
                                )}
                              </ThemeProvider>
                            </FormControl>
                          </div>
                          <div className={styles.roomSelectContainer}>
                            <Typography className={styles.roomTitle}>
                              Bathrooms
                            </Typography>
                            <FormControl fullWidth>
                              <ThemeProvider theme={theme}>
                                <InputLabel
                                  sx={{ fontFamily: "barlowregular" }}
                                >
                                  Please select..
                                </InputLabel>
                                <Select
                                  label="Please select.."
                                  value={formValue.aduBathroom}
                                  onBlur={handleBlur}
                                  error={Boolean(
                                    touched.aduBathroom && errors.aduBathroom
                                  )}
                                  // @ts-ignore
                                  helperText={
                                    touched.aduBathroom && errors.aduBathroom
                                  }
                                  sx={{
                                    color: "#212121",
                                    fontFamily: "barlowregular",
                                  }}
                                  onChange={(e) => {
                                    setFormValue({
                                      ...formValue,
                                      aduBathroom: e.target.value,
                                    });
                                  }}
                                  inputProps={{ "aria-label": "Without label" }}
                                >
                                  <MenuItem
                                    value={1}
                                    sx={{
                                      color: "#000000",
                                      fontFamily: "barlowregular",
                                    }}
                                  >
                                    1
                                  </MenuItem>
                                  <MenuItem
                                    value={2}
                                    sx={{
                                      color: "#000000",
                                      fontFamily: "barlowregular",
                                    }}
                                  >
                                    2
                                  </MenuItem>
                                  <MenuItem
                                    value={3}
                                    sx={{
                                      color: "#000000",
                                      fontFamily: "barlowregular",
                                    }}
                                  >
                                    3
                                  </MenuItem>
                                </Select>
                                {touched.aduBathroom && errors.aduBathroom && (
                                  <FormHelperText
                                    error
                                    id="standard-weight-helper-text-description"
                                    sx={{ marginLeft: "-0vh" }}
                                  >
                                    {errors.aduBathroom}
                                  </FormHelperText>
                                )}
                              </ThemeProvider>
                            </FormControl>
                          </div>
                        </Card>
                        <Card className={styles.cardTwo}>
                          <Typography className={styles.cardTitle}>
                            Option 2 JADU
                          </Typography>
                          <Typography className={styles.cardSubTitle}>
                            Select your preferences.
                          </Typography>
                          <div className={styles.feetContainer}>
                            <Typography className={styles.feetTitle}>
                              Square feet
                            </Typography>
                            <Typography className={styles.maxTitle}>
                              (Max.500)
                            </Typography>
                          </div>
                          <div className={styles.slider}>
                            <Slide
                              defaultValue={250}
                              step={100}
                              min={0}
                              max={500}
                              // @ts-ignore
                              onBlur={handleBlur}
                              error={Boolean(touched.sqft2 && errors.sqft2)}
                              helperText={touched.sqft2 && errors.sqft2}
                              value={formValue.sqft2}
                              handleChange={(e) => {
                                setFormValue({
                                  ...formValue,
                                  sqft2: e.target.value,
                                  priceId: "price_1M8zRZGUWr06e0k5bgwLRxkv",
                                });
                              }}
                              // inputError={inputError}
                            />
                            {touched.sqft2 && errors.sqft2 && (
                              <FormHelperText
                                error
                                id="standard-weight-helper-text-description"
                                sx={{ marginLeft: "-0vh" }}
                              >
                                {errors.sqft2}
                              </FormHelperText>
                            )}
                          </div>
                          <div className={styles.checbBoxContainer}>
                            <input
                              type="checkbox"
                              id="option2"
                              name="option2"
                              value="I want a Professional Recommendation"
                              className={styles.checkBtn}
                            />
                            <label
                              htmlFor="option2"
                              className={styles.checkLabel}
                            >
                              {" "}
                              I want a Professional Recommendation
                            </label>
                          </div>
                          <div className={styles.roomSelectContainer}>
                            <Typography className={styles.roomTitle}>
                              Bedrooms
                            </Typography>
                            <FormControl fullWidth>
                              <ThemeProvider theme={theme}>
                                <InputLabel
                                  sx={{ fontFamily: "barlowregular" }}
                                >
                                  Please select..
                                </InputLabel>
                                <Select
                                  value={formValue.jaduBedroom}
                                  label="Please select.."
                                  onBlur={handleBlur}
                                  error={Boolean(
                                    touched.jaduBedroom && errors.jaduBedroom
                                  )}
                                  // @ts-ignore
                                  helperText={
                                    touched.jaduBedroom && errors.jaduBedroom
                                  }
                                  sx={{
                                    color: "#212121",
                                    fontFamily: "barlowregular",
                                  }}
                                  onChange={(e) => {
                                    setFormValue({
                                      ...formValue,
                                      jaduBedroom: e.target.value,
                                    });
                                  }}
                                  inputProps={{ "aria-label": "Without label" }}
                                >
                                  <MenuItem
                                    value={1}
                                    sx={{
                                      color: "#000000",
                                      fontFamily: "barlowregular",
                                    }}
                                  >
                                    1
                                  </MenuItem>
                                  <MenuItem
                                    value={2}
                                    sx={{
                                      color: "#000000",
                                      fontFamily: "barlowregular",
                                    }}
                                  >
                                    2
                                  </MenuItem>
                                  <MenuItem
                                    value={3}
                                    sx={{
                                      color: "#000000",
                                      fontFamily: "barlowregular",
                                    }}
                                  >
                                    Studio
                                  </MenuItem>
                                </Select>
                                {touched.jaduBedroom && errors.jaduBedroom && (
                                  <FormHelperText
                                    error
                                    id="standard-weight-helper-text-description"
                                    sx={{ marginLeft: "-0vh" }}
                                  >
                                    {errors.jaduBedroom}
                                  </FormHelperText>
                                )}
                              </ThemeProvider>
                            </FormControl>
                          </div>
                          <div className={styles.roomSelectContainer}>
                            <Typography className={styles.roomTitle}>
                              Bathrooms
                            </Typography>
                            <FormControl fullWidth>
                              <ThemeProvider theme={theme}>
                                <InputLabel
                                  sx={{ fontFamily: "barlowregular" }}
                                >
                                  Please select..
                                </InputLabel>
                                <Select
                                  label="Please select.."
                                  value={formValue.jaduBathroom}
                                  onBlur={handleBlur}
                                  error={Boolean(
                                    touched.jaduBathroom && errors.jaduBathroom
                                  )}
                                  // @ts-ignore
                                  helperText={
                                    touched.jaduBathroom && errors.jaduBathroom
                                  }
                                  sx={{
                                    color: "#212121",
                                    fontFamily: "barlowregular",
                                  }}
                                  onChange={(e) => {
                                    setFormValue({
                                      ...formValue,
                                      jaduBathroom: e.target.value,
                                    });
                                  }}
                                  inputProps={{ "aria-label": "Without label" }}
                                >
                                  <MenuItem
                                    value={1}
                                    sx={{
                                      color: "#000000",
                                      fontFamily: "barlowregular",
                                    }}
                                  >
                                    1
                                  </MenuItem>
                                  <MenuItem
                                    value={2}
                                    sx={{
                                      color: "#000000",
                                      fontFamily: "barlowregular",
                                    }}
                                  >
                                    2
                                  </MenuItem>
                                </Select>
                                {touched.jaduBathroom &&
                                  errors.jaduBathroom && (
                                    <FormHelperText
                                      error
                                      id="standard-weight-helper-text-description"
                                      sx={{ marginLeft: "-0vh" }}
                                    >
                                      {errors.jaduBathroom}
                                    </FormHelperText>
                                  )}
                              </ThemeProvider>
                            </FormControl>
                          </div>
                        </Card>
                      </section>
                    </div>
                    <div className={styles.btnContainer}>
                      <Button
                        variant="outlined"
                        className={styles.backBtn}
                        onClick={() => setStep(step - 1)}
                      >
                        <img src={LeftIcon} alt="" /> BACK{" "}
                      </Button>
                      <Button
                        variant="contained"
                        className={styles.nextBtn}
                        disabled={isSubmitting}
                        type="submit"
                      >
                        NEXT <img src={RightIcon} alt="" />
                      </Button>
                    </div>
                  </section>
                </div>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </>
  );
};

export default Step5;
