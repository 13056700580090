import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from '../modules/Home/home';
import PageNotFound from '../modules/PageNotFound/PageNotFound';
import ROUTES from '../constants/paths';
import SuccessPage from '../modules/SuccessPage/SuccessPage';
import CancelPage from '../modules/CancelPage/CancelPage';
import React from 'react';

const RoutingPages = () => {
    return (
        <Router>
            <Routes>
                <Route path='/' element={<Home />} />
                <Route  path={ROUTES.successPage} element={<SuccessPage />} />
                <Route path={ROUTES.cancelPage} element={<CancelPage />} />
                <Route path="*" element={<PageNotFound />} />
            </Routes>
        </Router>
    )
}

export default RoutingPages;