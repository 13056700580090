// @ts-nocheck
import {
  Grid,
  Typography,
  FormControl,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import LeftIcon from "../../../assets/icons/LeftIcon.png";
import RightIcon from "../../../assets/icons/RightIcon.png";
// import FamilyZoning from '../../assets/icons/FamilyZoning'
import { createTheme, ThemeProvider } from "@mui/material/styles";
import * as Yup from "yup";
import { Formik } from "formik";
import isMountedRef from "../../../Hooks/useIsMountedRef";
import styles from "./step.module.scss";

const options = [
  { value: "Design & Permitting", label: "Design & Permitting" },
  { value: "Financing", label: "Financing" },
  { value: "Construction", label: "Construction" },
  { value: "Sales or Purchase", label: "Sales or Purchase" },
];

const MultiSelectButtonGroup = ({ name, options, onChange, value }) => {
  const handleButtonClick = (optionValue) => {
    const optionIndex = value.indexOf(optionValue);
    if (optionIndex > -1) {
      const newValue = value
        .split(",")
        .filter((v) => v !== optionValue)
        .join(",");
      onChange(name, newValue);
    } else {
      const newValue = value ? `${value},${optionValue}` : optionValue;
      onChange(name, newValue);
    }
  };

  return (
    <div className={styles.serviceBtnContainer}>
      {options.map((option) => (
        <button
          key={option.value}
          type="button"
          className={
            value.indexOf(option.value) > -1
              ? styles.selectedBtn1
              : styles.notSelectedBtn1
          }
          onClick={() => handleButtonClick(option.value)}
        >
          {option.label}
        </button>
      ))}
    </div>
  );
};

const Step3 = ({ stepThreeSubmit, ...other }) => {
  const { formValue, setFormValue, step, setStep } = other;
  const [selectedButtons, setSelectedButtons] = useState(
    formValue.servicesData
  );

  const initialValues = {
    ownProperty: formValue.ownProperty,
    residence: formValue.residence,
    startDate: formValue.startDate,
    yourGoal: formValue.yourGoal,
    servicesData: formValue.servicesData,
  };

  const handleFieldValueChange = (name, value) => {
    setFormValue((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const goalStatus1 = [
    { id: 1, value: "Investment purposes", name: "Investment purposes" },
    {
      id: 2,
      value: "To somoeone can live in home with us",
      name: "To somoeone can live in home with us",
    },
    {
      id: 3,
      value: "Force the value appreciation",
      name: "Force the value appreciation",
    },
    {
      id: 4,
      value: "Increase value of home and sell it",
      name: "Increase value of home and sell it",
    },
  ];

  const [goalStatus, setGoalStatus] = useState(goalStatus1);
  console.log(setGoalStatus);

  const handleButtonClick = (e) => {
    const id = e.target.id;
    const isSelected = selectedButtons.includes(id);
    if (isSelected) {
      setSelectedButtons(selectedButtons.filter((b) => b !== id));
    } else {
      setSelectedButtons([...selectedButtons, id]);
    }
  };
  console.log(handleButtonClick);

  const onSubmit = async (
    formValue,
    { setErrors, setStatus, setSubmitting }
  ) => {
    try {
      console.log("isSubmitting", formValue);
      stepThreeSubmit(formValue);
    } catch (error) {
      if (isMountedRef.current) {
        alert("Error");
        setStatus({ success: false });
        setErrors({ submit: error });
        setSubmitting(false);
      }
    }
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: "#212121",
      },
    },
  });

  const validationSchema = Yup.object().shape({
    ownProperty: Yup.string().required("This field is required"),
    residence: Yup.string().required("This field is required"),
    startDate: Yup.string().required("This field is required"),
    yourGoal: Yup.string().required("This field is required"),
    servicesData: Yup.string().required("This field is required"),
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  console.log(formValue, "formValue");
  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        enableReinitialize
        validationSchema={validationSchema}
        // sx={{ height: "100%" }}
      >
        {({
          errors,
          handleBlur,
          handleSubmit,
          isSubmitting,
          touched,
          // values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Grid container>
              <Grid item xs={12} sm={12} md={12}>
                <div className={styles.mainContainer}>
                  <section className={styles.subContainer}>
                    <div className={styles.titleContainer}>
                      <Typography className={styles.title}>
                        Project Information
                      </Typography>
                      <Typography className={styles.subTitle}>
                        Lorem ipsum dolor sit amet, et nomine patri sanctis.
                      </Typography>
                      <Typography className={styles.address}>
                        Your Address
                      </Typography>
                    </div>
                    <div className={styles.addressContainer}>
                      <section className={styles.addressSubContainer}>
                        <Typography className={styles.addressSubTitle}>
                          {formValue.streetAddress}
                        </Typography>

                        <div className={styles.propertyToggleBtn}>
                          {/* <Chip
                                        label="Single Family Zoning"
                                        deleteIcon={<FamilyZoning />}
                                        variant="outlined"
                                        className={styles.chipBtn}
                                        onDelete={() => { }}
                                    /> */}
                        </div>
                      </section>
                      <section className={styles.propertyContainer}>
                        <Typography className={styles.propertyTitle}>
                          Do you own this property?
                        </Typography>

                        <div className={styles.propertyToggleBtnContainer}>
                          <Button
                            variant="outlined"
                            type="button"
                            className={`${
                              formValue.ownProperty === "Yes"
                                ? styles.ownActiveBtn
                                : styles.ownInActiveBtn
                            }`}
                            value={formValue.ownProperty}
                            size="large"
                            onClick={(e) => {
                              setFormValue({
                                ...formValue,
                                ownProperty: "Yes",
                                esCrow: "No",
                              });
                            }}
                            sx={{ mr: 2 }}
                          >
                            Own
                          </Button>

                          <Button
                            variant="outlined"
                            type="button"
                            className={`${
                              formValue.ownProperty === "No"
                                ? styles.ownActiveBtn
                                : styles.ownInActiveBtn
                            }`}
                            onClick={(e) => {
                              setFormValue({
                                ...formValue,
                                esCrow: "Yes",
                                ownProperty: "No",
                              });
                              //  setGoalStatus(goalStatus2)
                            }}
                            size="large"
                          >
                            Escrow
                          </Button>
                        </div>
                      </section>
                      {touched.ownProperty && errors.ownProperty && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-description"
                          sx={{ marginTop: "-2vh" }}
                        >
                          {errors.ownProperty}
                        </FormHelperText>
                      )}
                    </div>

                    <div className={styles.residenceContainer}>
                      <Typography className={styles.residenceTitle}>
                        Is it your primary residence or are you an investor?{" "}
                      </Typography>

                      <div className={styles.residenceBtnContainer}>
                        <Button
                          variant="outlined"
                          type="button"
                          className={`${
                            formValue.residence === "Primary"
                              ? styles.primaryActiveBtn1
                              : styles.primaryInActiveBtn1
                          }`}
                          value={formValue.residence}
                          onClick={(e) => {
                            setFormValue({
                              ...formValue,
                              residence: "Primary",
                            });
                          }}
                          sx={{ mr: 2 }}
                        >
                          Primary
                        </Button>

                        <Button
                          variant="outlined"
                          type="button"
                          className={`${
                            formValue.residence === "Investment"
                              ? styles.primaryActiveBtn
                              : styles.primaryInActiveBtn
                          }`}
                          value={formValue.residence}
                          onClick={(e) => {
                            setFormValue({
                              ...formValue,
                              residence: "Investment",
                            });
                          }}
                        >
                          Investment
                        </Button>
                      </div>
                      {touched.residence && errors.residence && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-description"
                          sx={{
                            marginBottom: -5,
                            position: "absolute",
                            marginTop: "-0.2vh",
                          }}
                        >
                          {errors.residence}
                        </FormHelperText>
                      )}
                    </div>

                    <div
                      className={`${
                        formValue.residence === ""
                          ? styles.goalHideContainer
                          : styles.goalContainer
                      }`}
                    >
                      <Typography className={styles.goalTitle}>
                        What's your goal?
                      </Typography>

                      <FormControl
                        size="small"
                        fullWidth
                        sx={{ width: "50%", marginLeft: "0vh" }}
                        className={styles.dropDown}
                      >
                        <ThemeProvider theme={theme}>
                          <InputLabel sx={{ fontFamily: "barlowregular" }}>
                            Please select...
                          </InputLabel>

                          <Select
                            label="aaaaaaaaaaaa"
                            value={formValue.yourGoal}
                            className={styles.drop}
                            onChange={(e) => {
                              setFormValue({
                                ...formValue,
                                yourGoal: e.target.value,
                              });
                            }}
                            // displayEmpty
                            defaultValue={formValue.yourGoal}
                            onBlur={handleBlur}
                            error={Boolean(touched.yourGoal && errors.yourGoal)}
                            helperText={touched.yourGoal && errors.yourGoal}
                            sx={{ fontFamily: "barlowregular" }}
                          >
                            {goalStatus.map((option, index) => (
                              <MenuItem
                                key={option.id}
                                value={option.value}
                                sx={{
                                  color: "#000000",
                                  fontFamily: "barlowregular",
                                }}
                              >
                                {option.name}
                              </MenuItem>
                            ))}
                          </Select>
                          {touched.yourGoal && errors.yourGoal && (
                            <FormHelperText
                              error
                              id="standard-weight-helper-text-description"
                              sx={{
                                marginBottom: 1,
                                position: "relative",
                                right: "1.5vh",
                              }}
                            >
                              {errors.yourGoal}
                            </FormHelperText>
                          )}
                        </ThemeProvider>
                      </FormControl>
                    </div>

                    <div className={styles.projectSpecContainer}>
                      <Typography className={styles.projectTitle}>
                        Project Specifications
                      </Typography>

                      <div className={styles.projContainer}>
                        <Typography className={styles.projTitle}>
                          What’s your project start date?
                        </Typography>

                        <FormControl
                          size="small"
                          fullWidth
                          sx={{ width: "50%", marginLeft: "0vh" }}
                          className={styles.dropDown}
                        >
                          <ThemeProvider theme={theme}>
                            <InputLabel sx={{ fontFamily: "barlowregular" }}>
                              Please select...
                            </InputLabel>

                            <Select
                              value={formValue.startDate}
                              label="aaaaaaaaaaaa"
                              sx={{
                                color: "#212121",
                                fontFamily: "barlowregular",
                              }}
                              onChange={(e) => {
                                setFormValue({
                                  ...formValue,
                                  startDate: e.target.value,
                                });
                              }}
                              onBlur={handleBlur}
                              error={Boolean(
                                touched.startDate && errors.startDate
                              )}
                              // @ts-ignore
                              helperText={touched.startDate && errors.startDate}
                              defaultValue={formValue.startDate}
                              className={styles.drop}
                              placeholder="a"
                            >
                              <MenuItem
                                value="as soon as possible"
                                className={styles.drop}
                                sx={{
                                  color: "#000000",
                                  fontFamily: "barlowregular",
                                }}
                              >
                                As soon as Possible
                              </MenuItem>
                              <MenuItem
                                value="When I close escrow"
                                className={styles.drop}
                                sx={{
                                  color: "#000000",
                                  fontFamily: "barlowregular",
                                }}
                              >
                                When I close escrow
                              </MenuItem>
                              <MenuItem
                                value="Within six months"
                                className={styles.drop}
                                sx={{
                                  color: "#000000",
                                  fontFamily: "barlowregular",
                                }}
                              >
                                Within six months
                              </MenuItem>
                              <MenuItem
                                value="Iam not sure"
                                className={styles.drop}
                                sx={{
                                  color: "#000000",
                                  fontFamily: "barlowregular",
                                }}
                              >
                                I'm not sure
                              </MenuItem>
                            </Select>
                            {touched.startDate && errors.startDate && (
                              <FormHelperText
                                error
                                id="standard-weight-helper-text-description"
                                sx={{
                                  marginBottom: 1,
                                  position: "relative",
                                  right: "1.5vh",
                                }}
                              >
                                {errors.startDate}
                              </FormHelperText>
                            )}
                          </ThemeProvider>
                        </FormControl>
                      </div>
                    </div>
                    <div className={styles.serviceContainer}>
                      <Typography className={styles.serviceTitle}>
                        What services are you interested in?
                      </Typography>

                      <MultiSelectButtonGroup
                        name="servicesData"
                        options={options}
                        onChange={handleFieldValueChange}
                        value={formValue.servicesData}
                      />
                    </div>
                    {touched.servicesData && errors.servicesData && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-description"
                        sx={{ paddingLeft: 0.5 }}
                      >
                        {errors.servicesData}
                      </FormHelperText>
                    )}

                    <div className={styles.btnContainer}>
                      <Button
                        variant="outlined"
                        type="button"
                        className={styles.backBtn}
                        onClick={() => setStep(step - 1)}
                      >
                        <img src={LeftIcon} alt="" /> BACK{" "}
                      </Button>
                      <Button
                        variant="contained"
                        className={styles.nextBtn}
                        // onClick={onSubmit}
                        disabled={isSubmitting}
                        type="submit"
                      >
                        NEXT <img src={RightIcon} alt="" />
                      </Button>
                    </div>
                  </section>
                </div>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </>
  );
};

export default Step3;
