import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import edit from "../../../../assets/icons/edit.png";
import { useDispatch } from "react-redux";
import infoIcon2 from "../../../assets/icons/infoIcon2.png";
import styles from "./summary.module.scss";

const MobileViewSummary = ({ setStep, formValue, setCurrentPage }) => {
  const dispatch = useDispatch();

  const handleFormValue = () => {
    dispatch({
      type: "SET_FORM",
      payload: formValue,
    });
  };

  useEffect(() => {
    handleFormValue();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12}>
        <div className={styles.mainContainer}>
          <div className={styles.summaryTitleContainer}>
            <p className={styles.summaryTitle}>Summary</p>
            <p className={styles.projectTitle}>Project Information</p>
            <p className={styles.projectInfo}>
              Lorem ipsum dolor sit amet et nomine patri, et sanctis nom, et
              fili nomine sacti.
            </p>
          </div>
          <div className={styles.projectInfoContainer}>
            <p className={styles.projectSubTitle}>Project Information</p>
            <section className={styles.projectTitleContainer}>
              <p className={styles.addressTitle}>ADDRESS</p>
              <img
                src={edit}
                alt=""
                onClick={() => {
                  setStep(2);
                  setCurrentPage("Address");
                }}
                className={styles.editIcon}
              />
            </section>
            <p className={styles.addressSubTitle}> {formValue.streetAddress}</p>
            <span className={styles.zone}>
              Single Family Zoning
              <img src={infoIcon2} className={styles.infoIcon2} alt="" />
            </span>
          </div>
          <div className={styles.projectSpecContainer}>
            <section className={styles.projectSpecContainer}>
              <p className={styles.projectSpecTitle}>PROJECT SPECIFICATIONS</p>
              <img
                src={edit}
                alt=""
                onClick={() => {
                  setStep(3);
                  setCurrentPage("Project");
                }}
                className={styles.specEditIcon}
              />
            </section>
            <p className={styles.projectSpecSubTitle}>
              Are you a homeowner or investor?
            </p>
            <p className={styles.projectAns}> {formValue.residence}</p>
            <p className={styles.projectSpecSubTitle}>Do you own home?</p>
            <p className={styles.projectAns}>{formValue.ownProperty}</p>
            <p className={styles.projectSpecSubTitle}>Are you in escrow?</p>
            <p className={styles.projectAns}>{formValue.esCrow}</p>
            <p className={styles.projectSpecSubTitle}>Project start date</p>
            <p className={styles.projectAns}>{formValue.startDate}</p>
            <p className={styles.projectSpecSubTitle}>Services interested in</p>
            <p className={styles.projectAns}> {formValue.servicesData}</p>
          </div>
          <div className={styles.aduPrefContainer}>
            <section className={styles.aduPrefSubContainer}>
              <p className={styles.aduTitle}>ADU PREFERENCES</p>
              <img
                src={edit}
                alt=""
                onClick={() => {
                  setStep(4);
                  setCurrentPage("ADUPrefernce");
                }}
                className={styles.aduEditIcon}
              />
            </section>
            <p className={styles.aduTypeTitle}>ADU Type</p>
            <p className={styles.aduTypeTitleInfo}>
              Accesory Dwelling Unit - Detached
            </p>
            <div className={styles.aduDiv}>
              <section className={styles.optionOneContainer}>
                <p className={styles.optionOne}>OPTION 1</p>
                <p className={styles.squareFeetTitle}>Square feet</p>
                <p className={styles.squareFeetInfo}>{formValue.sqft1}</p>
              </section>
              <section className={styles.roomContainer}>
                <p className={styles.roomCount}>Bedroom</p>
                <p className={styles.roomCountInfo}>{formValue.aduBedroom}</p>
              </section>
            </div>
            <div className={styles.aduDiv}>
              <section className={styles.optionOneContainer}>
                <p className={styles.optionTwo}>OPTION 2</p>
                <p className={styles.squareFeetTitle}>Square feet</p>
                <p className={styles.squareFeetInfo}>{formValue.sqft1}</p>
              </section>
              <section className={styles.roomContainer}>
                <p className={styles.roomCount}>Bedroom</p>
                <p className={styles.roomCountInfo}>{formValue.aduBedroom}</p>
              </section>
            </div>
            <p className={styles.jaduTypeTitleInfo}>
              Junior Accesory Dwelling Unit - Garage
            </p>
            <div className={styles.jaduDiv}>
              <section className={styles.optionOneContainer}>
                <p className={styles.squareFeetTitle}>Square feet</p>
                <p className={styles.squareFeetInfo}>{formValue.sqft2}</p>
              </section>
              <section className={styles.roomContainer}>
                <p className={styles.roomCount}>Bedroom</p>
                <p className={styles.roomCountInfo}>{formValue.jaduBedroom}</p>
              </section>
            </div>
          </div>
          <div className={styles.contactInfoContainer}>
            <section className={styles.contactSubContainer}>
              <p className={styles.contactTitle}>CONTACT INFORMATION</p>
              <img
                src={edit}
                alt=""
                onClick={() => {
                  setStep(6);
                }}
                className={styles.contactEditIcon}
              />
            </section>
            <div className={styles.nameDiv}>
              <section className={styles.firstNameContainer}>
                <p className={styles.firstNameTitle}>First Name</p>
                <p className={styles.firstNameInfo}>{formValue.firstName}</p>
              </section>
              <section className={styles.lastNameContainer}>
                <p className={styles.lastNameTitle}>Last Name</p>
                <p className={styles.lastNameInfo}>{formValue.lastName}</p>
              </section>
            </div>
            <div className={styles.nameDiv}>
              <section className={styles.firstNameContainer}>
                <p className={styles.firstNameTitle}>Email Address</p>
                <p className={styles.firstNameInfo}>{formValue.emailId}</p>
              </section>
              <section className={styles.lastNameContainer}>
                <p className={styles.lastNameTitle}>Phone Number</p>
                <p className={styles.lastNameInfo}>(619) {formValue.phoneNumber}</p>
              </section>
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default MobileViewSummary;
