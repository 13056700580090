import { useState } from "react";
import { Grid } from "@mui/material";
import Step1 from "./step1/step1";
import Step2 from "./step2/step2";
import Step3 from "./step3/step3";
import Step4 from "./step4/step4";
import Step5 from "./step5/step5";
import Step6 from "./step6/step6";
import Step7 from "./step7/step7";
import Step8 from "./step8/step8";
import React from "react";

const FormAmplify = ({ step, setStep, setCurrentPage, currentpage }) => {
  const [formValue, setFormValue] = useState({
    step: 0,
    possibility: "",
    stateRegion: "",
    streetAddress: "",
    city: "",
    postalCode: "",
    ownProperty: "",
    esCrow: "",
    residence: "",
    startDate: "",
    yourGoal: "",
    services: "",
    aduType1: "",
    aduType2: "",
    specific: "",
    sqft1: "",
    sqft2: "",
    aduBedroom: "",
    aduBathroom: "",
    jaduBedroom: "",
    jaduBathroom: "",
    firstName: "",
    lastName: "",
    emailId: "",
    phoneNumber: "",
    transactionId: "",
    priceId: "",
    servicesData: "",
    payStatus: "",
  });

  console.log(formValue, "formValue");

  const stepOneSubmit = () => {
    setStep(2);
  };

  const stepTwoSubmit = () => {
    if (currentpage === "Address") {
      setStep(7);
    } else {
      setStep(3);
    }
  };

  const stepThreeSubmit = () => {
    if (currentpage === "Project") {
      setStep(7);
    } else {
      setStep(4);
    }
  };

  const stepFourSubmit = () => {
    setStep(5);
  };

  const stepFiveSubmit = () => {
    if (currentpage === "ADUPrefernce") {
      setStep(7);
    } else {
      setStep(6);
    }
  };

  const stepSixSubmit = () => {
    setStep(7);
  };

  const stepSevenSubmit = () => {
    setStep(8);
  };

  const renderComponent = () => {
    switch (step) {
      case 1:
        return (
          <Step1
            // @ts-ignore
            formValue={formValue}
            setFormValue={setFormValue}
            {...{ stepOneSubmit }}
          />
        );
      case 2:
        return (
          <Step2
            formValue={formValue}
            setFormValue={setFormValue}
            step={step}
            setStep={setStep}
            {...{ stepTwoSubmit }}
          />
        );
      case 3:
        return (
          <Step3
            formValue={formValue}
            setFormValue={setFormValue}
            step={step}
            setStep={setStep}
            {...{ stepThreeSubmit }}
          />
        );
      case 4:
        return (
          <Step4
            formValue={formValue}
            setFormValue={setFormValue}
            step={step}
            setStep={setStep}
            {...{ stepFourSubmit }}
          />
        );
      case 5:
        return (
          <Step5
            formValue={formValue}
            setFormValue={setFormValue}
            step={step}
            setStep={setStep}
            {...{ stepFiveSubmit }}
          />
        );
      case 6:
        return (
          <Step6
            formValue={formValue}
            setFormValue={setFormValue}
            step={step}
            setStep={setStep}
            {...{ stepSixSubmit }}
          />
        );
      case 7:
        return (
          <Step7
            currentpage={currentpage}
            setCurrentPage={setCurrentPage}
            formValue={formValue}
            setFormValue={setFormValue}
            step={step}
            setStep={setStep}
            {...{ stepSevenSubmit }}
          />
        );
      case 8:
        return (
          <Step8
            // @ts-ignore
            formValue={formValue}
            setFormValue={setFormValue}
            step={step}
            setStep={setStep}
            {...{ stepSevenSubmit }}
          />
        );
      default:
        // @ts-ignore
        return <Step1 newRequest={undefined} stepOneSubmit={undefined} />;
    }
  };
  return <Grid>{renderComponent()}</Grid>;
};

export default FormAmplify;
