import Container from "@mui/material/Container";
import Success from "../../Components/SuccessPage/SuccessPage";
import NavBar from "../../Components/navbar/navbar";
import StepperComp from "../../Components/stepperComp/stepperComp";
import React from "react";
import Loader from "../../Components/Loader/Loader";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import styles from "./success.module.scss";

const SuccessPage = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [payStatus, setPayStatus] = useState();
  const [items, setItems] = useState([]);
  let baseUrl = "https://api.adugeeks.us/transactionDetail/";
  let url = baseUrl.concat(items?.transactionId);
  const [currentUrl] = useState(window.location.href);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("formValues"));
    if (items) {
      setItems(items);
    }
  }, [currentUrl]);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("formValues"));
    if ((items && items.transactionId === "") || items === null) {
      const updatedUrl = currentUrl.replace(
        currentUrl,
        "https://report-dev.adugeeks.us/success"
      );
      window.location.href = updatedUrl;
    }
  }, [currentUrl, url]);

  async function createLead() {
    try {
      const response = await axios.post("https://api.adugeeks.us/createLead", {
        items: items,
      });
      console.log(response.data);
      localStorage.clear();
    } catch (error) {
      console.log(error, "lead error");
    }
  }

  useEffect(() => {
    const paymentInfo = setTimeout(() => {
      if (!url) {
        return;
      }
      async function getPaymentStatus() {
        try {
          const response = await fetch(url);
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const data = await response.json();
          setIsLoading(false);
          setPayStatus(data.paymentStaus || "");
          createLead();
          console.log(data, "payment status");
        } catch (error) {
          console.log(error, "error");
          setIsLoading(false);
        }
      }
      getPaymentStatus();
    }, 5000);

    return () => clearTimeout(paymentInfo);
  }, [url]);

  useEffect(() => {
    setItems((prevData) => ({
      ...prevData,
      payStatus: payStatus || "",
    }));
  }, [payStatus]);

  return (
    <div className={styles.mainContainer}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <NavBar />
          <div
            style={{
              position: "fixed",
              width: "100%",
              overFlow: "hidden",
              zIndex: 1000,
            }}
          >
            <StepperComp step={3} />
          </div>
          <Container sx={{ mt: 18 }}>
            <Success />
            {/* {payStatus === "paid" ? <Success /> : <Cancel />} */}
          </Container>
        </>
      )}
    </div>
  );
};

export default SuccessPage;
