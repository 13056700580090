import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import logo from '../../assets/logo.png';
import { useNavigate } from 'react-router-dom';

function ElevationScroll(props) {
  const { children, window } = props;

  
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

export default function NavBar(props) {
  const navigate = useNavigate();

  return (
    <div>
      <ElevationScroll {...props}>
        <AppBar color='inherit' position='fixed'>
          <Toolbar sx={{ml:2}}>
          <img src={logo} alt="" onClick={() => navigate("/")} style={{cursor: 'pointer'}}/>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Toolbar />
    </div>
  );
}
