import React, { useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import edit from "../../../../assets/icons/edit.png";
import { useDispatch } from "react-redux";
import styles from "./summary.module.scss";

const Summary = ({ setStep, formValue, setCurrentPage }) => {
  const dispatch = useDispatch();

  const handleFormValue = () => {
    dispatch({
      type: "SET_FORM",
      payload: formValue,
    });
  };

  useEffect(() => {
    handleFormValue();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Grid container>
      <Grid item xs={8} md={8} sm={8}>
        <div className={styles.mainContainer}>
          <Typography className={styles.summaryTitle}>Summary</Typography>
          <Typography className={styles.summarySubTitle}>
            Project Information
          </Typography>
          <div className={styles.addressContainer}>
            <Typography className={styles.addressTitle}>Address</Typography>
            <img
              src={edit}
              alt=""
              onClick={() => {
                setStep(2);
                setCurrentPage("Address");
              }}
            />
          </div>
          <div className={styles.addressSubContainer}>
            <div className={styles.addressSubCont}>
              <Typography className={styles.addressSubTitle}>
                {formValue.streetAddress}
              </Typography>
            </div>
            {/* <span className={styles.zone}>
              Single Family Zoning
              <img src={infoIcon2} className={styles.infoIcon2} alt="" />
            </span> */}
          </div>
          <div className={styles.addressContainer}>
            <Typography className={styles.addressTitle}>
              Project SPECIFICATIONS
            </Typography>
            <img
              src={edit}
              alt=""
              onClick={() => {
                setStep(3);
                setCurrentPage("Project");
              }}
            />
          </div>
          <div className={styles.userInfoContainer}>
            <section className={styles.investContainer}>
              <Typography className={styles.investTitle}>
                Are you a homeowner or investor?
              </Typography>
              <Typography className={styles.investAns}>
                {formValue.residence}
              </Typography>
            </section>
            <section className={styles.investContainer}>
              <Typography className={styles.investTitle}>
                Project start date
              </Typography>
              <Typography className={styles.investAns}>
                {formValue.startDate}
              </Typography>
            </section>
          </div>
          <div className={styles.userInfoContainer}>
            <section className={styles.investContainer}>
              <Typography className={styles.investTitle}>
                Do you own home?
              </Typography>
              <Typography className={styles.investAns}>
                {formValue.ownProperty}
              </Typography>
            </section>
            <section className={styles.investContainerone}>
              <Typography className={styles.investTitle}>
                Services interested in
              </Typography>
              <Typography className={styles.investAns}>
                {formValue.servicesData}
              </Typography>
            </section>
          </div>
          <div className={styles.userInfoContainer}>
            <section className={styles.investContainer}>
              <Typography className={styles.investTitle}>
                Are you in escrow?
              </Typography>
              <Typography className={styles.investAns}>
                {formValue.esCrow}
              </Typography>
            </section>
          </div>
          <div className={styles.addressContainer}>
            <Typography className={styles.addressTitle}>
              ADU PREFERENCES
            </Typography>
            <img
              src={edit}
              alt=""
              onClick={() => {
                setStep(4);
                setCurrentPage("ADUPrefernce");
              }}
            />
          </div>
          <div className={styles.userInfoContainer}>
            <section className={styles.investContainer}>
              <Typography className={styles.investTitle}>ADU Type</Typography>
              <Typography className={styles.investAns}>
                Accesory Dwelling Unit - Detached
              </Typography>
            </section>
          </div>
          <section className={styles.option}>
            <Typography className={styles.option}>OPTION1</Typography>
          </section>
          <div className={styles.optionContainer}>
            <section className={styles.bedRoom}>
              <Typography className={styles.squareFeet}>Square feet</Typography>
              <Typography className={styles.values}>
                {formValue.sqft1}
              </Typography>
            </section>
            <section className={styles.bedRoom}>
              <Typography className={styles.squareFeet}>Bedroom</Typography>
              <Typography className={styles.values}>
                {formValue.aduBedroom}
              </Typography>
            </section>
            <section className={styles.squareFeet}>
              <Typography className={styles.squareFeet}>Bathrooms</Typography>
              <Typography className={styles.values}>
                {formValue.aduBathroom}
              </Typography>
            </section>
          </div>
          <section className={styles.option}>
            <Typography className={styles.option}>OPTION2</Typography>
          </section>
          <div className={styles.optionContainer}>
            <section className={styles.squareFeet}>
              <Typography className={styles.squareFeet}>Square feet</Typography>
              <Typography className={styles.values}>
                {formValue.sqft2}
              </Typography>
            </section>
            <section className={styles.bedRoom}>
              <Typography className={styles.squareFeet}>Bedroom</Typography>
              <Typography className={styles.values}>
                {formValue.jaduBedroom}
              </Typography>
            </section>
            <section className={styles.bathRoom}>
              <Typography className={styles.squareFeet}>Bathrooms</Typography>
              <Typography className={styles.values}>
                {formValue.jaduBathroom}
              </Typography>
            </section>
          </div>
          <div className={styles.addressSubContainer}></div>
          <Typography className={styles.jadu}>
            Junior Accesory Dwelling Unit - Garage
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
};

export default Summary;
