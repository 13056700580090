import * as React from "react";
import Container from "@mui/material/Container";
import FormAmplify from "../../Components/form/Form";
import NavBar from "../../Components/navbar/navbar";
import LinearProgress from "@mui/material/LinearProgress";
import home from "../../Icons/home.png";
import home1 from "../../Icons/home1.png";
import setting from "../../Icons/setting.png";
import setting1 from "../../Icons/setting1.png";
import user from "../../Icons/user.png";
import user1 from "../../Icons/user1.png";
import getreport from "../../Icons/getreport.png";
import getreport1 from "../../Icons/getreport1.png";
import "../../modules/Home/home.scss";

const Home = () => {
  const [step, setStep] = React.useState(1);
  const [currentpage, setCurrentPage] = React.useState(0);
  let [imageFill, setImageFill] = React.useState(0);
  let progress1 = 0;
  let progress2 = 0;
  let progress3 = 0;

  //sets the background for images based on the step number
  React.useEffect(() => {
    if (step === 1 && imageFill > 0) {
      setTimeout(() => setImageFill(0), 300);
    }
    if (step >= 2 && step < 4) {
      setImageFill(1);
    }
    if (step === 4) {
      if (imageFill > 2) {
        setImageFill(1);
      } else {
        setTimeout(() => setImageFill(2), 300);
      }
    }
    if (step === 5) {
      setImageFill(2);
    }
    if (step === 6) {
      if (imageFill > 3) {
        setImageFill(3);
      } else {
        setTimeout(() => setImageFill(3), 300);
      }
    }
    if (step === 7) {
      setImageFill(3);
    }
    if (step === 8) {
      setTimeout(() => setImageFill(4), 300);
    }
  }, [step]);

  //Changes the valus of the progress bar based on the step number
  if (step === 1) {
    progress1 = 0;
    progress2 = 0;
    progress3 = 0;
  }
  if (step === 2) {
    progress1 = 30;
    progress2 = 0;
    progress3 = 0;
  }
  if (step === 3) {
    progress1 = 60;
    progress2 = 0;
    progress3 = 0;
  }
  if (step === 4) {
    progress1 = 100;
    progress2 = 0;
    progress3 = 0;
  }
  if (step === 5) {
    progress1 = 100;
    progress2 = 50;
    progress3 = 0;
  }
  if (step === 6) {
    progress1 = 100;
    progress2 = 100;
    progress3 = 0;
  }
  if (step === 7) {
    progress1 = 100;
    progress2 = 100;
    progress3 = 50;
  }
  if (step === 8) {
    progress1 = 100;
    progress2 = 100;
    progress3 = 100;
  }

  return (
    <>
      <div className="mainContainer">
        <div className="headContainer">
          <NavBar />

          {/* <StepperComp step={step / 4} /> */}
          <section className="stepsWrapper">
            <div className="stepssec">
              <div className="stepssecinner">
                {/* <div className='stepper'> */}
                {/* <Box>
                                        <LinearProgress variant="determinate" value={progress} className='stepper' />
                                    </Box> */}
                {/* </div> */}
                <div className="stepsflow">
                  <div className="stepperWrapper">
                    <div className="stepperItem">
                      <div
                        className={`stepCounter ${
                          imageFill >= 1 ? "bgOrange" : ""
                        }`}
                        id="emailstep"
                      >
                        <img
                          src={imageFill >= 1 ? home1 : home}
                          id="emailicon"
                        />
                      </div>
                      <div className="stepName">Enter Address</div>
                      <LinearProgress
                        variant="determinate"
                        value={progress1}
                        className="progress-bar"
                        color="warning"
                      />
                    </div>
                    <div className="stepperItem">
                      <div
                        className={`stepCounter ${
                          imageFill >= 2 ? "bgOrange" : ""
                        }`}
                      >
                        <img
                          src={imageFill >= 2 ? setting1 : setting}
                          id="aduicon"
                        />
                      </div>
                      <div className="stepName">ADU Preferences</div>
                      <LinearProgress
                        variant="determinate"
                        value={progress2}
                        className="progress-bar"
                        color="warning"
                      />
                    </div>
                    <div className="stepperItem">
                      <div
                        className={`stepCounter ${
                          imageFill >= 3 ? "bgOrange" : ""
                        }`}
                      >
                        <img src={imageFill >= 3 ? user1 : user} />
                      </div>
                      <div className="stepName">Contact & Payment Info</div>
                      <LinearProgress
                        variant="determinate"
                        value={progress3}
                        className="progress-bar"
                        color="warning"
                      />
                    </div>
                    <div className="stepperItem">
                      <div
                        className={`stepCounter ${
                          imageFill >= 4 ? "bgOrange" : ""
                        }`}
                      >
                        <img src={imageFill >= 4 ? getreport1 : getreport} />{" "}
                      </div>

                      <div className="stepName">Get Report Results</div>
                      {/* <i class="fa fa-file-text homeicon" id="reporticon"></i> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <Container>
          <FormAmplify
            step={step}
            setStep={setStep}
            currentpage={currentpage}
            setCurrentPage={setCurrentPage}
          />
        </Container>
      </div>
    </>
  );
};

export default Home;
