import React, { useEffect, useCallback, useState } from "react";
import styles from "./order.module.scss";
import OrderIcon from "../../../../Icons/ordericon.png";
import RightIcon from "../../../assets/icons/RightIcon.png";
import { Button, Card, Typography } from "@mui/material";
import axios from "axios";

const Order = ({ step, setStep, stepSevenSubmit, formValue, setFormValue }) => {
  const onSubmit = () => {
    stepSevenSubmit();
  };
  const [click, setClick] = useState(false);
  console.log(onSubmit);
  const handleSubmit = useCallback(async () => {
    setClick(true);
    try {
      const response = await axios.post(
        "https://api.adugeeks.us/createCheckoutSession",
        { price: formValue.priceId }
      );
      setFormValue((formValue) => ({
        ...formValue,
        transactionId: response?.data?.sessionId,
      }));
      window.open(response?.data?.checkoutUrl, "_self");
    } catch (error) {
      console.log("failed!");
    }
  }, [formValue, setFormValue]);

  useEffect(() => {
    localStorage.setItem("formValues", JSON.stringify(formValue));
  }, [formValue]);

  useEffect(() => {
    if (click) {
      setTimeout(() => {
        setClick(false);
      }, 5000);
    }
  }, [click]);

  return (
    <Card className={styles.orderPage}>
      <Typography className={styles.orderTitle}>Order </Typography>
      <div className={styles.reportContainer}>
        <img src={OrderIcon} alt="" />
        {formValue.priceId === "price_1M8zRZGUWr06e0k5bgwLRxkv" ? (
          <div className={styles.titleCard}>
            <Typography className={styles.propertyTitle}>
              Property Check Report
            </Typography>
            <Typography className={styles.propertyRate}>$400</Typography>
          </div>
        ) : (
          <div className={styles.titleCard}>
            <Typography className={styles.propertyTitle}>
              Property Check Report
            </Typography>
            <Typography className={styles.propertyRate}>$300</Typography>
          </div>
        )}
      </div>
      <div className={styles.taxContainer}>
        <Typography className={styles.taxTitle}>Taxes</Typography>
        <Typography className={styles.priceTitle}>$4.00</Typography>
      </div>
      <div className={styles.taxContainer}>
        <Typography className={styles.totalTitle}>Total</Typography>
        {formValue.priceId === "price_1M8zRZGUWr06e0k5bgwLRxkv" ? (
          <Typography className={styles.priceTitle}>$400.00</Typography>
        ) : (
          <Typography className={styles.priceTitle}>$300.00</Typography>
        )}
      </div>
      <Typography className={styles.termsAndConditions}>
        By clicking submit you agree to ADU Geeks{" "}
        {/* <a href="/" className={styles.policyTag}>
          Privacy Policy
        </a>{" "} */}
        {/* and */}
        Terms and{" "}
        <a
          href="https://www.adugeeks.com/terms-and-conditions"
          target="_blank"
          className={styles.policyTag}
        >
          Agreements
        </a>
      </Typography>
      <div className={styles.btnContainer}>
        {click === true ? (
          <Button
            variant="contained"
            fullWidth
            className={styles.nextBtn}
            onClick={() => {}}
          >
            Please Wait
          </Button>
        ) : (
          <Button
            variant="contained"
            fullWidth
            className={styles.nextBtn}
            onClick={handleSubmit}
          >
            SUBMIT <img src={RightIcon} alt="" />
          </Button>
        )}
        <Button
          variant="text"
          className={styles.backBtn}
          onClick={() => setStep(step - 1)}
        >
          BACK
        </Button>
      </div>
    </Card>
  );
};

export default Order;
