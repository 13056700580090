

const  AduPaths = {
  successPage: '/SuccessPage',
  pageNotFound: '/PageNotFound',
  cancelPage: '/CancelPage',
  home: '/Home',
}

const ROUTES = {
  ...AduPaths,
};



export default ROUTES;