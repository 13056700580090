import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import parse from "autosuggest-highlight/parse";
import { debounce } from "@mui/material/utils";

const GOOGLE_MAPS_API_KEY = "AIzaSyAo_pwSYaEf4fmyFxWdGdvjaUPQsj6b4VA";

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

export default function AutoSuggestion({ ...other }) {
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  // @ts-ignore
  const [active, setActive] = React.useState(true);

  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);
  const { formValue, setFormValue } = other;
  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      // @ts-ignore
      debounce((request, callback) => {
        if (autocompleteService.current && request.input) {
          // @ts-ignore
          autocompleteService.current.getPlacePredictions(
            {
              ...request,
              types: ["address"],
            },
            (results) => {
              if (active) {
                let newOptions = [];

                if (value) {
                  newOptions = [value];
                }

                if (results) {
                  newOptions = [...newOptions, ...results];
                }

                // @ts-ignore
                setOptions(newOptions);
              }
            }
          );
        } else {
          setOptions(value ? [value] : []);
        }
      }, 400),
    [value]
  );
  React.useEffect(() => {
    // @ts-ignore
    let isActive = true;

    // Update isActive when component is unmounted
    return () => {
      isActive = false;
    };

    // Fetch autocomplete results and update options state
  }, [value, inputValue, fetch]);
  React.useEffect(() => {
    let active = true;

    // @ts-ignore
    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        // @ts-ignore
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        // @ts-ignore
        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      fullWidth
      sx={{ maxWidth: "100%" }}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={formValue.streetAddress}
      noOptionsText="No locations"
      // @ts-ignore
      onChange={(event, newValue) => {
        // @ts-ignore
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        console.log(newValue, "test");

        if (newValue) {
          const placeId = newValue.place_id;
          // @ts-ignore
          const service = new window.google.maps.places.PlacesService(
            document.createElement("div")
          );
          service.getDetails({ placeId }, (place, status) => {
            // @ts-ignore
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
              console.log("Street:", getAddressComponent(place, "route"));
              console.log("City:", getAddressComponent(place, "locality"));
              console.log("Country:", getAddressComponent(place, "country"));
              console.log(
                "Postal Code:",
                getAddressComponent(place, "postal_code")
              );
              setFormValue({
                ...formValue,
                streetAddress: newValue.description,
                city: getAddressComponent(place, "locality"),
                country: getAddressComponent(place, "country"),
                postalCode: getAddressComponent(place, "postal_code"),
                stateRegion: getAddressComponent(
                  place,
                  "administrative_area_level_1"
                ),
              });
            }
          });
        } else {
          setFormValue({
            ...formValue,
            streetAddress: newValue.description,
            city: "",
            stateRegion: "",
            postalCode: "",
          });
        }
        // @ts-ignore
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
      }}
      // @ts-ignore
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          InputLabelProps={{
            style: {
              color: "#212121",
              fontFamily: "robotoregular",
            },
          }}
          label="Street Address"
          sx={{
            "& .MuiAutocomplete-endAdornment": {
              display: "none",
            },
            "& .MuiAutocomplete-input": {
              fontFamily: "robotoregular",
            },
          }}
          fullWidth
          // placeholder="Street Address"
          size="small"
        />
      )}
      renderOption={(props, option) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings || [];

        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length])
        );

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: "flex", width: 44 }}>
                <LocationOnIcon sx={{ color: "text.secondary" }} />
              </Grid>
              <Grid
                item
                sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}
              >
                {parts.map((part, index) => (
                  <Box
                    key={index}
                    component="span"
                    sx={{ fontWeight: part.highlight ? "bold" : "regular" }}
                  >
                    {part.text}
                  </Box>
                ))}

                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}
function getAddressComponent(place, componentType) {
  const component = place.address_components.find((ac) =>
    ac.types.includes(componentType)
  );
  return component ? component.short_name : "";
}
