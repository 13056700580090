import NavBar from "../../Components/navbar/navbar";
import StepperComp from "../../Components/stepperComp/stepperComp";
import Container from "@mui/material/Container";
import styles from "./cancel.module.scss";
import React, { useEffect, useState } from "react";
import Cancel from "../../Components/CancelPage/Cancel";

const CancelPage = () => {
  const [currentUrl] = useState(window.location.href);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("formValues"));
    if ((items && items.transactionId !== "") || items !== null) {
      const updatedUrl = currentUrl.replace(
        currentUrl,
        "https://report-dev.adugeeks.us/cancel"
      );
      window.location.href = updatedUrl;
    }
  }, [currentUrl]);

  return (
    <div className={styles.mainContainer}>
      <NavBar />
      <div
        style={{
          position: "fixed",
          width: "100%",
          overFlow: "hidden",
          zIndex: 1000,
        }}
      >
        <StepperComp step={3} />
      </div>
      <Container sx={{ mt: 18 }}>
        <Cancel />
      </Container>
    </div>
  );
};

export default CancelPage;
