import {
  Button,
  Card,
  Grid,
  Typography,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import { createMuiTheme, ThemeProvider } from "@mui/material/styles";
import React, { useEffect } from "react";
import RightIcon from "../../../assets/icons/RightIcon.png";
import pointers from "../../../assets/icons/pointers.png";
import styles from "./step.module.scss";

const Step1 = ({ stepOneSubmit }) => {
  const onSubmit = () => {
    stepOneSubmit();
  };

  const theme = createMuiTheme({
    typography: {
      fontFamily: ["barlowregular"].join(","),
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12}>
        <div className={styles.mainContainer}>
          <div className={styles.subContainer}>
            <Typography className={styles.title}>
              Get to know the best possibilities for ADU construction in your
              property
            </Typography>
            <Typography className={styles.subTitle}>
              This is the address where you plan to build your ADU.
            </Typography>
            <Card className={styles.card}>
              <ThemeProvider theme={theme}>
                <FormGroup sx={{ fontFamily: "barlowregular" }}>
                  <FormControlLabel
                    control={
                      <img src={pointers} className={styles.pointIcon} />
                    }
                    label="Lorem Ipsum dolor sit amet et nomine et. "
                  />

                  <FormControlLabel
                    control={
                      <img src={pointers} className={styles.pointIcon} />
                    }
                    className={styles.formContent}
                    label="Lorem Ipsum dolor sit amet et nomine, singular nom.   "
                  />

                  <FormControlLabel
                    control={
                      <img src={pointers} className={styles.pointIcon} />
                    }
                    className={styles.formContent}
                    label="Lorem Ipsum dolor sit amet et nomine sim ne.  "
                  />

                  <FormControlLabel
                    control={
                      <img src={pointers} className={styles.pointIcon} />
                    }
                    className={styles.formContent}
                    label="Lorem Ipsum dolor sit amet et nomine. "
                  />
                </FormGroup>
              </ThemeProvider>
            </Card>

            <Button
              variant="contained"
              className={styles.button}
              onClick={onSubmit}
            >
              Start Now <img src={RightIcon} alt="" />
            </Button>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={12} sx={{ mt: 24 }}>
        <div className={styles.mobContainer}>
          <Typography className={styles.mobTitle}>
            Get to know the best possibilities for ADU construction in your
            property
          </Typography>
          <Typography className={styles.mobSubTitle}>
            This is the address where you plan to build your ADU.
          </Typography>

          <Card className={styles.mobCard}>
            <ThemeProvider theme={theme}>
              <FormGroup sx={{ fontFamily: "barlowregular" }}>
                <FormControlLabel
                  control={
                    <img src={pointers} className={styles.pointMobIcon} />
                  }
                  label="Lorem Ipsum dolor sit amet et nomine et. "
                />

                <FormControlLabel
                  control={
                    <img src={pointers} className={styles.pointMobIcon} />
                  }
                  className={styles.formMobContent}
                  label="Lorem Ipsum dolor sit amet et nomine, singular nom."
                />

                <FormControlLabel
                  control={
                    <img src={pointers} className={styles.pointMobIcon} />
                  }
                  className={styles.formMobContent}
                  label="Lorem Ipsum dolor sit amet et nomine sim ne."
                />

                <FormControlLabel
                  control={
                    <img src={pointers} className={styles.pointMobIcon} />
                  }
                  className={styles.formMobContent}
                  label="Lorem Ipsum dolor sit amet et nomine."
                />
              </FormGroup>
            </ThemeProvider>
          </Card>
        </div>
      </Grid>

      <Grid item xs={12} sm={12} md={12}>
        <div className={styles.btnMobContainer}>
          <Button
            variant="contained"
            className={styles.nextMobBtn}
            onClick={onSubmit}
          >
            START NOW <img src={RightIcon} alt="" />
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

export default Step1;
