import * as Yup from "yup";
import { Formik } from "formik";
import isMountedRef from "../../../Hooks/useIsMountedRef";
import { Grid, Typography, Button, TextField } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import React, { useEffect } from "react";
import LeftIcon from "../../../assets/icons/LeftIcon.png";
import RightIcon from "../../../assets/icons/RightIcon.png";
import styles from "./step.module.scss";

const Step6 = ({ stepSixSubmit, ...other }) => {
  const { formValue, setFormValue, step, setStep } = other;

  const theme = createTheme({
    palette: {
      primary: {
        main: "#212121",
      },
    },
  });

  const initialValues = {
    firstName: formValue.firstName,
    lastName: formValue.lastName,
    emailId: formValue.emailId,
    phoneNumber: formValue.phoneNumber,
  };

  const usPhoneNumberRegex = /^\d{3}-\d{4}$/;

  const onSubmit = async (
    formValue,
    { setErrors, setStatus, setSubmitting }
  ) => {
    try {
      console.log("isSubmitting", formValue);
      stepSixSubmit(formValue);
    } catch (error) {
      // @ts-ignore
      if (isMountedRef.current) {
        setStatus({ success: false });
        setErrors({ submit: error });
        setSubmitting(false);
      }
    }
  };
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("This field is Required"),
    lastName: Yup.string().required("This field is Required"),
    emailId: Yup.string()
      .required("This field is Required")
      .email("Enter the valid email address"),

    phoneNumber: Yup.string()
      .matches(usPhoneNumberRegex, "Phone number is not valid")
      .required("This field is Required")
      .min(7, "minimum charaters must be 8 digits"),
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // console.log(formValue, 'formValue')
  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        enableReinitialize
        validationSchema={validationSchema}
        sx={{ height: "100%" }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          setFieldValue,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Grid container>
              <Grid item xs={12} sm={12} md={12}>
                <div className={styles.mainContainer}>
                  <section className={styles.subContainer}>
                    <Typography className={styles.title}>
                      Contact Information
                    </Typography>
                    <Typography className={styles.subTitle}>
                      Lorem ipsum dolor sit amet et nomine patri, et sanctis
                      nom, et fili nomine sacti.
                    </Typography>
                    <div className={styles.contactContainer}>
                      <section className={styles.inputFields}>
                        <ThemeProvider theme={theme}>
                          <TextField
                            id="outlined-basic"
                            label="First Name"
                            required
                            // onBlur={handleBlur}
                            error={
                              touched.firstName && Boolean(errors.firstName)
                            }
                            // helperText={touched.firstName && errors.firstName}
                            placeholder={values.firstName}
                            value={values.firstName}
                            onChange={(e, value) => {
                              setFieldValue("firstName", e.target.value);
                              setFormValue({
                                ...formValue,
                                firstName: e.target.value,
                              });
                              console.log(value, "test");
                            }}
                            size="small"
                            fullWidth
                            className={styles.firstName}
                            InputLabelProps={{
                              style: {
                                fontFamily: "barlowregular",
                                fontSize: "16px",
                              },
                            }}
                            sx={{
                              input: {
                                color: "#212121",
                                fontFamily: "barlowregular",
                                fontSize: "16px",
                                fontWeight: "400",
                              },
                            }}
                          />
                          <TextField
                            id="outlined-basic"
                            label="Last Name"
                            required
                            // onBlur={handleBlur}
                            error={touched.lastName && Boolean(errors.lastName)}
                            // helperText={touched.lastName && errors.lastName}
                            placeholder={values.lastName}
                            value={values.lastName}
                            onChange={(e, value) => {
                              setFieldValue("lastName", e.target.value);
                              setFormValue({
                                ...formValue,
                                lastName: e.target.value,
                              });
                            }}
                            size="small"
                            fullWidth
                            className={styles.lastName}
                            InputLabelProps={{
                              style: {
                                fontFamily: "barlowregular",
                                fontSize: "16px",
                              },
                            }}
                            sx={{
                              input: {
                                color: "#212121",
                                fontFamily: "barlowregular",
                                fontSize: "16px",
                                fontWeight: "400",
                              },
                            }}
                          />
                        </ThemeProvider>
                      </section>
                      <section className={styles.inputFields}>
                        <ThemeProvider theme={theme}>
                          <TextField
                            id="outlined-basic"
                            label="Email Address"
                            type="email"
                            name="email"
                            error={touched.emailId && Boolean(errors.emailId)}
                            // helperText={touched.emailId && errors.emailId}
                            // @ts-ignore
                            pattern="[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-zA-Z]{2,4}"
                            required
                            // onBlur={handleBlur}
                            // error={Boolean(touched.emailId && errors.emailId)}
                            value={values.emailId}
                            onChange={(e, value) => {
                              // setFieldValue("emailId", e.target.value);
                              setFormValue({
                                ...formValue,
                                emailId: e.target.value,
                              });
                              handleChange("emailId")(e.target.value);
                            }}
                            size="small"
                            fullWidth
                            className={styles.firstName}
                            InputLabelProps={{
                              style: {
                                fontFamily: "barlowregular",
                                fontSize: "16px",
                              },
                            }}
                            sx={{
                              input: {
                                fontFamily: "barlowregular",
                                fontWeight: "400",
                              },
                            }}
                          />
                          <>
                            <TextField
                              id="outlined-basic"
                              label="Phone Number"
                              error={
                                touched.phoneNumber &&
                                Boolean(errors.phoneNumber)
                              }
                              // helperText={
                              //   touched.phoneNumber && errors.phoneNumber
                              // }
                              required
                              placeholder={values.phoneNumber}
                              type="text"
                              InputProps={{
                                startAdornment: (
                                  <Typography
                                    sx={{
                                      fontFamily: "barlowregular",
                                      color: "#212121",
                                      marginRight:'1vh'
                                    }}
                                  >
                                    (619)
                                  </Typography>
                                ),
                              }}

                              value={values.phoneNumber}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                const digitsOnly = inputValue.replace(
                                  /\D/g,
                                  ""
                                ); // Remove non-digit characters
                                let formattedValue = "";

                                if (digitsOnly.length > 0) {
                                  formattedValue = digitsOnly.slice(0, 3);

                                  if (digitsOnly.length > 3) {
                                    formattedValue +=
                                      "-" + digitsOnly.slice(3, 7);
                                  }

                                  if (digitsOnly.length > 7) {
                                    return; // Exit early if more than seven digits are entered
                                  }
                                }
                                handleChange("phoneNumber")(formattedValue);
                                setFormValue({
                                  ...formValue,
                                  phoneNumber: formattedValue,
                                });
                                if (digitsOnly.length === 0) {
                              
                                }
                              }}
                              size="small"
                              fullWidth
                              className={styles.lastName}
                              InputLabelProps={{
                                style: {
                                  fontFamily: "barlowregular",
                                  fontSize: "16px",
                                },
                              }}
                              sx={{
                                input: {
                                  color: "#212121",
                                  fontFamily: "barlowregular",
                                  fontSize: "16px",
                                  fontWeight: "400",
                                },
                              }}
                            />
                          </>
                        </ThemeProvider>
                      </section>
                    </div>
                    <div className={styles.btnContainer}>
                      <Button
                        variant="outlined"
                        className={styles.backBtn}
                        onClick={() => setStep(step - 1)}
                      >
                        <img src={LeftIcon} alt="" /> BACK{" "}
                      </Button>
                      <Button
                        variant="contained"
                        className={styles.nextBtn}
                        // onClick={onSubmit}
                        type="submit"
                        disabled={isSubmitting}
                      >
                        NEXT <img src={RightIcon} alt="" />
                      </Button>
                    </div>
                  </section>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <div className={styles.btnMobContainer}>
                  <Button
                    variant="outlined"
                    className={styles.backMobBtn}
                    onClick={() => setStep(step - 1)}
                  >
                    <img src={LeftIcon} alt="" /> BACK{" "}
                  </Button>
                  <Button
                    variant="contained"
                    className={styles.nextMobBtn}
                    type="submit"
                    disabled={isSubmitting}
                  >
                    NEXT <img src={RightIcon} alt="" />
                  </Button>
                </div>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </>
  );
};

export default Step6;
