// @ts-nocheck
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Grid,
  Typography,
  Button,
  TextField,
  FormHelperText,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import LeftIcon from "../../../assets/icons/LeftIcon.png";
import isMountedRef from "../../../Hooks/useIsMountedRef";
import RightIcon from "../../../assets/icons/RightIcon.png";
import AutoSuggestion from "./AutoSuggestion";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import styles from "./step.module.scss";

const Step2 = ({ stepTwoSubmit, ...other }) => {
  const { formValue, setFormValue, step, setStep } = other;
  const [inputError, setInputError] = useState(false);
  console.log(setInputError);
  const theme = createTheme({
    palette: {
      primary: {
        main: "#212121",
      },
    },
  });

  const initialValues = {
    stateRegion: formValue.stateRegion,
    streetAddress: formValue.streetAddress,
    city: formValue.city,
    postalCode: formValue.postalCode,
  };

  const onSubmit = async (
    formValue,
    { setErrors, setStatus, setSubmitting }
  ) => {
    try {
      console.log("isSubmitting", formValue);
      stepTwoSubmit(formValue);
    } catch (error) {
      // @ts-ignore
      if (isMountedRef.current) {
        setStatus({ success: false });
        setErrors({ submit: error });
        setSubmitting(false);
      }
    }
  };
  const handleChange = (e) => {
    const newValue = e.target.value;
    if (/^\d{0,5}$/.test(newValue)) {
      setFormValue({ ...formValue, postalCode: newValue });
    }
  };

  const validationSchema = Yup.object().shape({
    stateRegion: Yup.string().required("This field is required"),
    streetAddress: Yup.string().required("This field is required"),
    city: Yup.string().required("This field is required"),
    postalCode: Yup.string().required("This field is required"),
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        enableReinitialize
        validationSchema={validationSchema}
        sx={{ height: "100%" }}
      >
        {({ errors, handleBlur, handleSubmit, isSubmitting, touched }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} sx={{ mt: 6 }}>
                <div className={styles.mainContainer}>
                  <section className={styles.subContainer}>
                    <Typography className={styles.title}>
                      Please type in your address
                    </Typography>
                    <Typography className={styles.subTitle}>
                      This is the address where you plan to build your ADU
                    </Typography>
                    <ThemeProvider theme={theme}>
                      <div className={styles.addressContainer}>
                        <section className={styles.inputFields}>
                          <AutoSuggestion
                            formValue={formValue}
                            setFormValue={setFormValue}
                            inputError={inputError}
                          />
                        </section>
                        {touched.streetAddress && errors.streetAddress && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-description"
                          >
                            {errors.streetAddress}
                          </FormHelperText>
                        )}
                        <section className={styles.inputFields}>
                          <TextField
                            id="outlined-textarea"
                            required
                            onBlur={handleBlur}
                            error={Boolean(touched.city && errors.city)}
                            label="City"
                            type="text"
                            name="City"
                            className={styles.firstName}
                            value={formValue.city}
                            onChange={(e) =>
                              setFormValue({
                                ...formValue,
                                city: e.target.value,
                              })
                            }
                            size="small"
                            fullWidth
                            InputLabelProps={{
                              style: {
                                color: "#212121",
                                fontFamily: "robotoregular",
                              },
                            }}
                            sx={{
                              input: {
                                fontFamily: "robotoregular",
                                fontSize: "16px",
                              },
                            }}
                          />
                          <div className={styles.lastName}>
                            <TextField
                              id="outlined-basic"
                              autoComplete="on"
                              label="State/Region"
                              required
                              onBlur={handleBlur}
                              error={Boolean(
                                touched.stateRegion && errors.stateRegion
                              )}
                              type="text"
                              name="State"
                              value={formValue.stateRegion}
                              onChange={(e) =>
                                setFormValue({
                                  ...formValue,
                                  stateRegion: e.target.value,
                                })
                              }
                              size="small"
                              fullWidth
                              InputLabelProps={{
                                style: {
                                  color: "#212121",
                                  fontFamily: "robotoregular",
                                },
                              }}
                              sx={{
                                input: {
                                  fontFamily: "robotoregular",
                                  fontSize: "16px",
                                },
                              }}
                            />
                          </div>

                          <TextField
                            id="outlined-basic"
                            autoComplete="on"
                            required
                            onBlur={handleBlur}
                            error={Boolean(
                              touched.postalCode && errors.postalCode
                            )}
                            label="Postal Code"
                            value={formValue.postalCode}
                            type="text"
                            onChange={handleChange}
                            size="small"
                            fullWidth
                            className={styles.lastName}
                            InputLabelProps={{
                              style: {
                                color: "#212121",
                                fontFamily: "robotoregular",
                              },
                            }}
                            sx={{
                              input: {
                                fontFamily: "robotoregular",
                                fontSize: "16px",
                              },
                            }}
                          />
                        </section>
                      </div>
                    </ThemeProvider>
                    <div className={styles.btnContainer}>
                      <Button
                        variant="outlined"
                        className={styles.backBtn}
                        onClick={() => setStep(step - 1)}
                      >
                        <img src={LeftIcon} alt="" /> BACK{" "}
                      </Button>

                      <Button
                        variant="contained"
                        className={styles.nextBtn}
                        disabled={isSubmitting}
                        type="submit"
                      >
                        NEXT <img src={RightIcon} alt="" />
                      </Button>
                    </div>
                  </section>
                </div>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </>
  );
};

export default Step2;
